@import '../src/assets/variable/variable.scss';

* {
  font-family: $ACUMIN_PROREGULAR;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// ::-webkit-scrollbar-track
// {
//   width: 0px;
// }

// ::-webkit-scrollbar
// {
// background-color: transparent;
// }

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }