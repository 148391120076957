//BACKGROUND_COLOR
$BG_BLACK: #000000;
$BG_LIGHT_BLACK: #151515;
$BG_MEDIUM_BLACK: #333333;
$BG_WHITE: #ffffff;
$BG_DARK_BLUE: #182C3D;
$BG_DASHBOARD_BLUE: #122C3D;
$BG_LIGHT_BLUE: #8ED1FC;
$BG_LINE_BLUE: #8ed1fc57;
$BG_RED: #FF5858;
$BG_GRAY: #97A7B5;
$BG_GREEN: #00DCB4;
$BG_LIGHT_GRAY: #ABABAB;
$BG_DARK_GRAY: #5B5B5B;
$BG_DARK_GREEN: #00A1BF;
$BG_COVER_WHITE: #F2F5F9;
$BG_PIE_GREEN: #3AC1D3;
$BG_HISTORY_GRAY: #787878;
$BG_BTN_GRAY:#cbd3da;
$BG_TRANS: #8ed1fcad;
$BG_MOBILE_GREEN: #124259bd;
$BG_MOBILE_LIGHT_GREEN: #028075;
$BG_ACCOUNT_GRAY:#F8F8F8;
$BG_CHILD1:#7BE7D3;
$BG_CHILD2:#97CEBE;
$BG_CHILD3:#B4B5A9;
$BG_CHILD4:#D09C93;
$BG_CHILD5:#ED827D;
$BG:radial-gradient(210.58% 239.02% at 37.78% -12.25%, #124259 0%, #1A2D41 100%);



//TEXT_COLOR
$TXT: #122C3D;
$TXT_BLACK: #000000;
$TXT_LIGHT_BLACK: #151515;
$TXT_MEDIUM_BLACK: #333333;
$TXT_WHITE: #ffffff;
$TXT_DARK_BLUE: #182C3D;
$TXT_LIGHT_BLUE: #8ED1FC;
$TXT_RED: #FF5858;
$TXT_RED_ERROR: #ff5858ed;
$TXT_GRAY: #97A7B5;
$TXT_GREEN: #00DCB4;
$TXT_LIGHT_GRAY: #ABABAB;
$TXT_DARK_GRAY: #5B5B5B;
$TXT_DARK_GREEN: #00A1BF;
$TXT_COVER_WHITE: #F2F5F9;
$TXT_PIE_GREEN: #3AC1D3;
$TXT_HISTORY_GRAY: #787878;
$TXT_FORGOT_PASS: #656565;
$TXT_BTN_GRAY:#cbd3da;
$TXT_BROWN: #5B5B5B;
$TXT_MOBILE_GREEN: #124259bd;
$TXT_MOBILE_LIGHT_GREEN: #028075;
$POP_UP_GRAY: #524F4F;
$TXT_BORDER_GREEN: #30B5CB;
$TXT_HISTORY_GRAY:#8E8E8E;
$TXT_HISTORY_BORDER_GRAY:#BFC3C7;
$TXT_SELECTED_BLUE:#0E212F;





//FONT_SIZE
$FONT10: 10px;
$FONT12: 12px;
$FONT14: 14px;
$FONT16: 16px;
$FONT18: 18px;
$FONT20: 20px;
$FONT22: 22px;
$FONT24: 24px;
$FONT26: 26px;
$FONT28: 28px;
$FONT30: 30px;
$FONT32: 32px;
$FONT36: 36px;
$FONT48: 48px;

//FONT_FAMILY
$ACUMIN_PROBOLD: 'acumin_probold';
$ACUMIN_PROREGULAR: 'Acumin_Pro';