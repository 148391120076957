@import "../../assets/variable/variable.scss";

.tostMain {
    .MuiAlert-root {
        background-color: $BG_WHITE;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 381px;
        font-family: $ACUMIN_PROREGULAR;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.15px;
        color: $TXT_BROWN;
        .MuiAlertTitle-root {
            font-family: $ACUMIN_PROBOLD;
            font-size: 24px;
            line-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $TXT_GREEN;
        }
    }
    .Error{
        .MuiAlert-root {
            .MuiAlertTitle-root { 
                color: #FF5858;
                font-weight: 700;
            }
        }
    }

    .MuiAlert-icon {
        display: none;
      
    }
    .MuiSnackbarContent-message {
        display: flex;
        align-items: center;
        letter-spacing: 0.15px;
        line-height: 24px;
        font-size: 16px;
        color: $BG_DARK_GRAY  !important;
       
    }
    .MuiAlert-message {
        display: flex;
        align-items: center;
        text-align: center;
        font-family: $ACUMIN_PROREGULAR;
        font-size: $FONT16;
        line-height: 24px;
        letter-spacing: 0.15px;
        flex-direction: column;
        color: $TXT_DARK_GRAY;
    }


}